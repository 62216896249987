<template>
    <div class="keepet_home site_page">
        <div class="blur_bg">
            <div class="wrapper_page">
                <div>
                    <header style="height:80px;line-height:80px;">
                        <div class="main_cont flex-between head_cont">
                            <v-img
                                max-height="35"
                                max-width="180"
                                src="../assets/imgs/qinban_logo.png"
                            ></v-img>
                            <div class="lists_title flex-end color-snow fw5 fz18">
                                <div v-for="(item,index) in tabsLists" :key="index" @mouseover="tabHover(index)" @click="gotoLink(item.type)">{{item.name}}</div>
                                <div :class="['liner_botm','lineP'+ hoverInd ]" ></div>
                            </div>
                        </div>
                    </header>
                    <div class="cont_box flex-center">
                        <div class="video_box">
                            <div class="video_srcs">
                                <video class="my_video" muted="muted" preload="auto" autoplay loop="true" id="video">
                                    <source src="https://cdn.keepet.cn/video/version220527.mp4" type="video/mp4">
                                    <track default="" kind="captions" srclang="en" src="">
                                </video>
                            </div>
                            <div class="video_iphone"></div>
                        </div>
                        <div class="right_cont flex-around flex-col">
                            <div class="color-snow same_widt  mt30">
                                <v-img
                                    max-height="233"
                                    max-width="339"
                                    src="../assets/imgs/text_new.png"
                                ></v-img>
                                <!-- <div class="color-snow fz24 text-left same_widt mt30">
                                    <div class="lineHe50">· 轻松查询宠粮原料及成分</div>
                                    <div class="lineHe50">· 分享真实的养宠经验</div>
                                    <div>· 记录宠物成长日记</div>
                                </div> -->
                                <div class="qrcode_mian same_widt mt80">
                                    <div class="qrcode_box flex-center">
                                        <v-img
                                            max-height="120"
                                            max-width="120"
                                            src="../assets/imgs/qrcode_img.png"
                                            transition="slide-x-transition"
                                        ></v-img>
                                    </div>
                                    <div class="color-snow mt10 pl20 text-left">微信扫码体验</div>
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="main_cont">
                <div class="flex-center pb40">
                    <v-img
                        max-height="42"
                        max-width="330"
                        src="../assets/imgs/text-jdcw.png"
                    ></v-img>
                </div>
                <div class="liner_bottom mb30"></div>
                <div class="fz12 company_infos pb40 flex-between">
                    <div>Copyright © 2021 - 2024 keepet.cn・<a href="https://beian.miit.gov.cn" target="_blank">沪ICP备17041590号-2</a></div>
                    <div class="flex-end pr10">
                        <v-tooltip top :color="'transparent'">
                            <template v-slot:activator="{ on, attrs }">
                                <v-img
                                    height="34"
                                    width="34"
                                    v-bind="attrs"
                                    v-on="on"
                                    class="mr20 img_cur"
                                    src="../assets/imgs/img_wechat.png"
                                >
                                </v-img>
                            </template>
                            <div class="hover_cont flex-center">
                                <v-img
                                    max-height="113"
                                    max-width="96"
                                    src="../assets/imgs/wx_qrcode.png"
                                >
                                </v-img>
                            </div>
                        </v-tooltip>
                        <v-img
                            height="34"
                            width="34"
                            class="mr20 img_cur"
                            src="../assets/imgs/redbook.png"
                            @click="gotoLink('redbook')"
                        >
                        </v-img>
                        <v-img
                            height="34"
                            width="34"
                            class="mr20 img_cur"
                            src="../assets/imgs/img_zhihu.png"
                            @click="gotoLink('zhihu')"
                        >
                        </v-img>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Home',
    props: {
      
    },
    data() {
        return{
            hoverInd:0,
            tabsLists:[
                {name:'首页',type:''},
                // {name:'养宠指南',type:'guide'},
                {name:'关于我们',type:'about'},
                // {name:'使用帮助',type:''}
            ]
        }
    },
    methods: {
        tabHover(index) {
            this.hoverInd = index
        },
        gotoLink(type) {
            let link = type == 'redbook' ? 'https://www.xiaohongshu.com/user/profile/557a39c962a60c0c3fc46c16':
                type == 'zhihu' ? 'https://www.zhihu.com/org/keepetchong-wu-ji':
                type == 'guide' ? 'https://portal.keepet.pet/pet-guide/' :
                type == 'about' ? 'https://mp.weixin.qq.com/s/VohLLw2XABd0WVbPX50WQw' :''
            if (link !='') { 
                window.open(link,'_blank')
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .site_page {
        width: 100%;
        position: relative;
        background: rgba(0,11,28,0.25) url('../assets/imgs/bg.png') top no-repeat;
        background-size: cover;
        background-color: rgba(0,11,28,0.25);
    }
    .blur_bg {
        background-color: rgba(0,11,28,0.25);
    }
    .site_page .wrapper_page{
        height: 100vh;
        width: 100%;
    }
    header {
        padding: 0 30px;
        width: 100%;
        min-width: 800px;
        left: 0;
        top: 0;
        overflow: hidden;
        margin: 0 auto;
        z-index: 2;
        -webkit-transition: height 400ms;
        transition: height 400ms;
        background: none;
    }
    .main_cont {
        max-width: 1200px;
        margin: 0 auto;
        &.head_cont{
            height: 80px;
        }
    }
    .lists_title {
        position: relative;
        >div{
            line-height: 40px;
            margin-right: 50px;
            cursor: pointer;
            &:nth-child(4n) {
                margin-right: 0;
            }
        }
        .liner_botm {
            position: absolute;
            z-index: 3;
            width: 30px;
            bottom: 0;
            right: 5px;
            height: 2px;
            background:#ff678e;
            transition: all 0.3s;
            &.lineP0 {
                right: 0;
                left: 2px;
            }
            &.lineP1 {
                right: 215px;
                // width: 80px;
            }
            &.lineP2 {
                right: 92px;
                // width: 80px;
            }
            &.lineP3 {
                right: -30px;
                // width: 80px;
            }
        }
    }
    .cont_box {
        height: calc(100vh - 160px);
        overflow: hidden;
        margin: 0 auto;
        .video_box {
            position: relative;
                width: 260px;
                height: 564px;
            .video_srcs {
                position: absolute;
                width: 260px;
                height: 564px;
                margin: 18px 20px;
                .my_video {
                    width: 260px;
                    height: 565px;
                }
            }
            .video_iphone {
                position: absolute;
                width: 300px;
                height: 600px;
                background: url('../assets/imgs/phone_bg.png') no-repeat center;
                background-size: contain;
            }
        }
    }
    .right_cont {
        flex-shrink: 0;
        width: 600px;
        height: calc(100vh - 300px);
        margin-left: 100px;
        .same_widt {
            width: 600px;
        }
        .qrcode_box {
            width: 145px;
            height: 145px;
            background-color: #fff;
            border-radius: 10px;
        }
    }
    .liner_bottom {
        height: 1px;
        background-color: rgba(255,255,255,0.4);
    }
    .company_infos,
    .company_infos a {
        color: rgba(255,255,255,0.8);
        text-decoration-line: none;
    }
    .hover_cont {
        width: 130px;
        height: 139px;
        background:url('../assets/imgs/rectangle_bg.png') no-repeat center;
        background-size: cover;
    }
    .img_cur {
        cursor: pointer;
    }
</style>
