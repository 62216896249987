<template>
    <v-app>
        <v-main>
            <template v-if="numeral>3">
                <Home />
            </template>
            <template v-else>
                <Mobile />
            </template>
        </v-main>
    </v-app>
</template>

<script>
    import Home from './components/Home';
    import Mobile from './components/Mobile';
    export default {
        name: 'App',
        components: {
            Home,Mobile
        },
        data: () => ({
            screenWidth: document.body.clientWidth,
            numeral:8,
            timer:false,
            isAlive:false
        }),
        watch: {
            screenWidth(val) {
                // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
                if (!this.timer) {
                    // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
                    this.screenWidth = val;
                    this.timer = true;
                    const that = this;
                    setTimeout(function () {
                        that.sjcount(that.screenWidth);//窗口大小变化后执行的方法
                        that.reload();//窗口大小变化后执行的方法
                        that.timer = false;
                    }, 1000);
                }
            }
        },
        mounted(){
            const that = this;
            window.onresize = () => (() => {
                window.screenWidth = document.body.clientWidth;
                that.screenWidth = window.screenWidth;
            })();
            that.sjcount(that.screenWidth)
        },
        methods:{
            reload() {
                this.isAlive = false;
                this.$nextTick(function () {
                    this.isAlive = true;
                });
            },
            sjcount(screewidth) {
                if (screewidth >= 1920) {
                    this.numeral = 8;
                } else if (screewidth >= 1680) {
                    this.numeral = 7;
                } else if (screewidth >= 1600) {
                    this.numeral = 5;
                } else if (screewidth >= 1280) {
                    this.numeral = 5;
                } else if (screewidth >= 750) {
                    this.numeral = 4;
                }else{
                    this.numeral = 3
                }
            },
 
        }
    };
</script>
<style lang="scss">
    @import './assets/css/common.scss';
</style>
