<template>
    <div class="mobile_page">
        <div class="blur_bg">
            <div class="wrapper_page">
                <div class="header_mobile flex-between">
                    <v-img
                        max-height="23"
                        max-width="120"
                        src="../assets/imgs/qb_logo_black.png"
                    >
                    </v-img>
                    <v-img
                        max-height="40"
                        max-width="40"
                        @click.stop="drawer = !drawer"
                        src="../assets/imgs/ico_list.png"
                    >
                    </v-img>
                </div>
                <v-navigation-drawer
                    v-model="drawer"
                    absolute
                    right
                    width="170"
                    temporary
                >
                    <div class="flex-end pt20 pr20 mb30">
                        <v-img
                            max-height="22"
                            max-width="22"
                            @click.stop="drawer = !drawer"
                            src="../assets/imgs/close_ico.png"
                        >
                        </v-img>
                    </div>
                    <v-list
                        nav
                        dense
                    >
                        <v-list-item-group
                            v-model="group"
                        >
                            <v-list-item>
                                <v-list-item-title class="fz16 color-black fw4">
                                    <div class="tit_mobile">首页</div>
                                </v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item>
                                <v-list-item-title class="fz16 color-black fw4" @click="gotoLink('guide')">
                                    <div class="tit_mobile">养宠指南</div>
                                </v-list-item-title>
                            </v-list-item> -->
                            <v-list-item>
                                <v-list-item-title class="fz16 color-black fw4" @click="gotoLink('about')">
                                    <div class="tit_mobile">关于我们</div>
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-title class="fz16 color-black fw4">
                                    <div class="tit_mobile">使用帮助</div>
                                </v-list-item-title>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-navigation-drawer>
                <div class="pl45 mt155">
                    <v-img
                        max-height="146"
                        max-width="281"
                        src="../assets/imgs/mobile_title.png"
                    >
                    </v-img>
                    <div class="qrcode_img mt80">
                        <v-img
                            max-height="153"
                            max-width="155"
                            src="../assets/imgs/mobile_qrcode.png"
                        >
                        </v-img>
                    </div>
                    <div class="botm_bars">
                        <div class="flex-center mb20">
                            <v-img
                                max-height="32"
                                max-width="32"
                                class="mr20"
                                @click="overlay = !overlay"
                                src="../assets/imgs/img_wechat.png"
                            >
                            </v-img>
                            <v-img
                                max-height="32"
                                max-width="32"
                                class="mr20"
                                src="../assets/imgs/redbook.png"
                                @click="gotoLink('redbook')"
                            >
                            </v-img>
                            <v-img
                                max-height="32"
                                max-width="32"
                                src="../assets/imgs/img_zhihu.png"
                                @click="gotoLink('zhihu')"
                            >
                            </v-img>
                        </div>
                        <div class="fz12 company_infos">Copyright © 2021 - 2024 keepet.cn・<a href="https://beian.miit.gov.cn" target="_blank">沪ICP备17041590号-2</a></div>
                    </div>
                </div>
            </div>
            <v-overlay
                :absolute="absolute"
                :value="overlay"
            >
                <div class="qrcode_layer">
                    <div class="flex-end">
                        <v-img
                            max-height="22"
                            max-width="22"
                            @click="overlay = !overlay"
                            src="../assets/imgs/close_ico.png"
                        >
                        </v-img>
                    </div>
                    <v-img
                        max-height="170"
                        max-width="144"
                        class="centr_img"
                        src="../assets/imgs/wx_qrcode.png"
                    >
                    </v-img>
                </div>
            </v-overlay>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Mobile',
    props: {
      
    },
    data() {
        return{
            absolute: true,
            overlay: false,
            drawer: false,
            group: null,
        }
    },
    watch: {
        group () {
            this.drawer = false
        },
    },
    methods: {
        gotoLink(type) {
            let link = type == 'redbook' ? 'https://www.xiaohongshu.com/user/profile/557a39c962a60c0c3fc46c16':
                type == 'zhihu' ? 'https://www.zhihu.com/org/keepetchong-wu-ji':
                type == 'guide' ? 'https://portal.keepet.cn/pet-guide/' :
                type == 'about' ? 'https://mp.weixin.qq.com/s/VohLLw2XABd0WVbPX50WQw' : ''
            if (link !='') {       
                window.open(link,'_blank')
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
    .mobile_page {
        width: 100%;
        position: relative;
        background: rgba(0,11,28,0.25) url('../assets/imgs/bg.png') top no-repeat;
        background-size: cover;
        background-color: rgba(0,11,28,0.25);

        .blur_bg {
            background-color: rgba(0,11,28,0.25);
        }
        .wrapper_page {
            height: 100vh;
            width: 100%;
        }
        .header_mobile {
            position: sticky;
            height: 50px;
            background: #fff;
            padding: 0 10px;
            z-index: 5;
        }
        .qrcode_img {
            border-radius: 10px;
            padding: 10px;
            width: 125px;
            height: 125px;
        }
        .botm_bars {
            position: absolute;
            z-index: 5;
            bottom: 25px;
            left: 0;
            width: 100%;
        }
        .company_infos,
        .company_infos a {
            color: rgba(255,255,255,0.8);
            text-decoration-line: none;
        }
    }
    .qrcode_layer {
        background: #fff;
        padding: 10px 20px 50px;
        border-radius: 10px;
        .centr_img {
            margin:30px 20px 0;
        }
    }
    .tit_mobile {
        height: 40px;
        line-height: 40px;
        width: 80px;
        margin: 0 auto;
    }
</style>
